import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { HelpersService, ImageService } from '@shared/services';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiRequestsService } from '@api/api.gateway';
import { PRODUCT_TYPES, UPSELL_TYPES } from '@shared/global.types';

@Component({
  selector: 'create-product-modal',
  templateUrl: './create-product-modal.component.html',
  styleUrls: ['./create-product-modal.component.scss']
})
export class CreateProductModal extends AbstractComponent {
  productTypes = PRODUCT_TYPES;
  upsells = [];

  displayImage = { url: '' };
  files: FileList;
  properties: any = {
    data: {},
    update: false,
    type: 'book',
  };
  productForm: FormGroup;

  collections: any[] = [];
  categories: any[] = [];
  authors: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private api: ApiRequestsService,
    private helpers: HelpersService,
    private imageService: ImageService,
  ) {
    super();
  }

  ngOnInit() {
    this.bootstrapForm(this.properties.data || {});
    this.getCollections();
    this.getCategories();
    this.getAuthors();

    this.subscriptions.push( this.productForm.controls['name'].valueChanges.subscribe(value => {
      const slug = this.helpers.getSlug(value);
      this.productForm.patchValue({
        slug,
        key: slug,
      });

      this.productForm.get('slug').markAsDirty();
      this.productForm.get('key').markAsDirty();
    }) );

    this.upsells = Object.values(UPSELL_TYPES).map((upsell) => ({ value: upsell, name: upsell }));
  }

  /**
   * Handle form submission
   * @param formLogin form data
   */
  async onSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      this.helpers.displayFormErrors(this.productForm);
      return;
    }
    let payload = value;
    this.helpers.setformAsValid(this.productForm);
    if (this.properties.update) {
      payload = this.getDirtyValues(this.productForm);
    }
    payload.attributes = this.parseAttributes(value.attributes);

    if (payload.to && !payload.to.parent)
      delete payload.to.parent;

    if (this.files) {
      payload.cover = await this.imageService.uploadPicture(value.slug, this.files);
    }

    if (this.properties.onSubmit) {
      try {
        await this.properties.onSubmit({ payload });
      } catch (error) {
        this.helpers.errorMessage(error);
        return;
      }
    }
    this.activeModal.close({ payload });
  }

  close() {
    this.activeModal.close(false);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  /**
   *
   * @param cg Base form hroup
   * @returns a dict with the modified values
   */
  private getDirtyValues(cg) {
    const dirtyValues = {};  // initialize empty object
    Object.keys(cg.controls).forEach((c) => {
       const currentControl = cg.get(c);

       if (currentControl.dirty) {
          if (currentControl.controls) {
            dirtyValues[c] = this.getDirtyValues(currentControl);
          } else {
            dirtyValues[c] = currentControl.value;
          }
       }

      });
    return dirtyValues;
  }

  /**
 * listen when file input change
 * @param event input file event
 */
   async loadPicture(event: any) {
    this.files = event.target.files;
    this.displayImage = await this.imageService.getMediaAsBase64(this.files[0]);
  }

  private bootstrapForm(data: any = {}) {
    data.cover = data.cover || {};
    data.to = data.to || {};
    this.displayImage.url = data.cover.thumbnail;

    const attributes = this.fb.array(this.parseAttributesToArray(data.attributes));

    const cover =  this.formBuilder.group({
      original: [
        { value: data.cover.original, disabled: false },
      ],
      thumbnail: [
        { value: data.cover.thumbnail, disabled: false },
      ],
      medium: [
        { value: data.cover.medium, disabled: false },
      ]
    });
    const to =  this.formBuilder.group({
      parent: [
        { value: data.to.parent, disabled: false },
      ],
      type: [
        { value: data.to.type, disabled: false },
      ],
      field: [
        { value: data.to.field, disabled: false },
      ]
    });

    this.productForm = this.formBuilder.group({
      name: [
        { value: data.name || '', disabled: false },
        Validators.required,
      ],
      title: [
        { value: data.title || '', disabled: false },
        Validators.required,
      ],
      description: [
        { value: data.description || '', disabled: false },
      ],
      key: [
        { value: data.key || '', disabled: false },
        Validators.required,
      ],
      tags: [
        { value: data.tags || '', disabled: false },
      ],
      type: [
        { value: data.type || (this.properties.type  !== PRODUCT_TYPES.UPSELLS ? this.properties.type : '') , disabled: false },
        Validators.required,
      ],
      slug: [
        { value: data.slug || '', disabled: false },
        Validators.required,
      ],
      sku: [
        { value: data.sku || '', disabled: false },
      ],
      pages_count: [
        { value: data.pages_count, disabled: false },
      ],
      authors: [
        { value: data.authors, disabled: false },
      ],
      categories: [
        { value: data.categories || [], disabled: false },
      ],
      owner_id: [
        { value: data.owner_id, disabled: false },
      ],
      collection_id: [
        { value: data.collection_id, disabled: false },
      ],
      base_product_id: [
        { value: data.base_product_id, disabled: false },
      ],
      status: [
        { value: data.status || 'inactive', disabled: false },
      ],
      attributes,
      cover,
      to,
    });
  }

  /**
   * Add form row
   */
  addRow() {
    const control = this.productForm.get('attributes') as FormArray;
    control.push(this.initFormExtra());
  }

  /**
   * Delete form row by index
   * @param index row index
   */
  deleteRow(index: number) {
    const control = this.productForm.get('attributes') as FormArray;
    control.removeAt(index);
  }

  initFormExtra(key = '', value = '') {
    return this.fb.group({
      key: [key],
      value: [value],
    });
  }

  get getAttributeFormControls() {
    return this.productForm.get('attributes') as FormArray;
  }

  getName() {
    return `products.${this.properties.type}`;
  }

  getAction() {
    return `${this.properties.update ? 'Editar' : 'Crear' }`;
  }

  private getCollections() {
    this.api.core.collections({}).toPromise()
      .then(res => {
        this.collections = res['data'] || [];
      })
      .catch(e => this.helpers.errorMessage(e));
  }

  private getAuthors() {
    this.api.core.authors({}).toPromise()
      .then(res => {
        this.authors = res['data'] || [];
      })
      .catch(e => this.helpers.errorMessage(e));
  }

  private getCategories() {
    this.api.products.getCategories({ all: true, limit: 100 }).toPromise()
      .then(res => {
        this.categories = res['data'] || [];
      })
      .catch(e => this.helpers.errorMessage(e));
  }

  private parseAttributes(attributes: any[]) {
    return attributes.reduce((accumulator, { key, value }) => {
      accumulator[key] = value;
      return accumulator;
    }, {});
  }

  private parseAttributesToArray(attributes) {
    const formArray = [];
    if ( attributes ) {
      Object.keys(attributes).forEach( key => {
        formArray.push(this.initFormExtra(key, attributes[key]));
      });
    }
    return formArray;
  }

}
