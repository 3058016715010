// Declare all modals that will going to be used as shared filters

import { ProductExtrasModal } from './product-extras/product-extras.component';
import { AddGiftBoxModal } from './add-box/add-box.component';
import { AddUpsellModal } from './add-upsell/add-upsell.component';
import { AudioBookNameModal } from './audio-book-name/audio-book-name.component';
import { EditBasePricesModal } from './edit-base-prices/edit-base-prices.component';
import { ExtrasModal } from './extras/extras.component';
import { ProductAddExtrasModal } from './product-add-extras/product-add-extras.component';
import { ProductAvailabilityModal } from './product-availability/product-availability.component';

// this declaration could be found in the shared app module
export function ProductModals() {
  return [
    AudioBookNameModal,
    EditBasePricesModal,
    ExtrasModal,
    ProductExtrasModal,
    AddGiftBoxModal,
    AddUpsellModal,
    ProductAddExtrasModal,
    ProductAvailabilityModal
  ];
}
