import { Injectable } from '@angular/core';
import { AppHttpClient } from '../api.interceptor';
import { environment } from '@env/environment';
@Injectable()
export class OrdersRequests {
  private config = environment;

  constructor(
    private http: AppHttpClient
  ) {
  }

  /**
   * GET a list of statuses
   * @param payload: Object<any>
   */
  statuses(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/statuses`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET a list of orders
   * @param queryParams
   */
  orders(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET order schedule data
   * @param queryParams
   */
  orderAutoSendData(orderId?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/order-items/order-auto-send/${orderId}`;
    return this.http.get(url);
  }

  orderAutoSend(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/pack-automation/send`;
    return this.http.post(url, payload);
  }

  /**
   * GET a list of orders
   * @param queryParams
   */
  createAmazonOrder(body?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/amazon`;
    return this.http.file(url, body, true);
  }

  /**
   * GET a list of orders
   * @param queryParams
   */
  resetOrders(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/reset-orders`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET a list of payments
   * @param queryParams
   */
  payments(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/payment`;
    return this.http.get(url, queryParams);
  }

  /**
   * POST Unsubscribe an user
   * @param queryParams
   */
  unsubscribe(orderId) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/payment/unsubscribe`;
    return this.http.post(url, { orderId });
  }

  /**
   * POST refund a payment
   * @param queryParams
   */
  refund(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/payment/refund/${id}`;
    return this.http.post(url, data);
  }

  /**
   * GET a list of orders
   * @param queryParams
   */
  ordersWithErrors(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/errors`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET a list of items
   * @param queryParams
   */
  orderItems(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/order-items`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET a list of items
   * @param queryParams
   */
  addOrderItem(body?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/order-items`;
    return this.http.post(url, body);
  }

  /**
   * Update an order item
   * @param queryParams
   */
  updateOrderItem(id, body?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/order-items/${id}`;
    return this.http.put(url, body);
  }

  /**
   * Get an order item by id
   * @param queryParams
   */
  getOrderItem(id) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/order-items/${id}`;
    return this.http.get(url);
  }

  /**
   * GET a list of payment methods
   * @param queryParams
   */
  paymentMethods(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/payment-methods`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET historical status from an order
   * @param id order id
   * @param queryParams filter params
   */
  orderStatus(id, queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/${id}/statuses?sort=-created_at&page=1&limit=1000`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET single order data
   * @param id order id
   * @param queryParams filter params
   */
  orderDetails(id, queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/${id}`;
    return this.http.get(url, queryParams);
  }
  partnerOrderDetails(uid, queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/partner-orders/${uid}`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET available orders
   * @param queryParams filter params
   */
  availableOrders(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/packs`;
    return this.http.get(url, queryParams);
  }

  sendLabelCorreosExpress(options) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/packs/send/correos-express`;
    return this.http.post(url, options);
  }

  /**
   * GET overview
   */
  orderOverview(query?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/overview`;
    return this.http.get(url, query);
  }

  /**
   * POST change status
   */
  changeStatus(uuid, data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/${uuid}/statuses`;
    return this.http.post(url, data);
  }

  /**
   * POST reset order
   */
  resetOrder(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/${id}/reset`;
    return this.http.post(url, data);
  }

  /**
   * POST join order
   */
  joinOrder(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/${id}/join`;
    return this.http.post(url, data);
  }

  /**
   * POST reset order
   */
  resetBook(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/${id}/reset/book`;
    return this.http.post(url, data);
  }

  /**
   * POST reset order
   */
  updateOrder(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/${id}/info`;
    return this.http.patch(url, data);
  }

  /**
   * PATCH reset order
   */
  reprintOrder(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/${id}/info`;
    return this.http.patch(url, data);
  }

  /**
   * DELETE delete item
   */
  deleteOrderItem(id, payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/order-items/${id}/remove`;
    return this.http.delete(url, payload);
  }

  /*
   * POST create pack
   */
  createPack(data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/packs`;
    return this.http.post(url, data);
  }

  /**
   * GET a list of packs
   * @param payload: Object<any>
   */
  packs(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/packs`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET a overview of packs
   * @param payload: Object<any>
   */
  packOverview(queryParams = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/packs/overview`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET pack by id
   * @param payload: Object<any>
   */
  getPackById(id) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/packs/${id}`;
    return this.http.get(url);
  }

  triggerPack(id) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/packs/${id}/send`;
    return this.http.post(url, {});
  }
  /**
   * GET a list of printers
   * @param payload: Object<any>
   */
  printers(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/printers`;
    return this.http.get(url, queryParams);
  }

  priceCheck(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/printers/check-prices`;
    return this.http.post(url, queryParams);
  }

  /**
   * GET a list of printers
   * @param payload: Object<any>
   */
  deliveryCouriers(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/delivery-couriers`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET a list of printer events
   * @param payload: Object<any>
   */
  getPrinterEvents(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/printers/events`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET a list of printer errors
   * @param payload: Object<any>
   */
  getPrinterErrors(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/printers/errors`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET the count of unseen notifications (errors)
   */
   getPackErrorCount() {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/printers/notifications`;
    return this.http.get(url);
  }

  triggerOldGenerator(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/order-items/pdfs/old`;
    return this.http.post(url, payload);
  }

  markAsSeen(id, printerError) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/printers/markAsSeen/${id}`;
    return this.http.post(url, { printerError });
  }

  sendCompleteOrderEmail(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/email-complete`;
    return this.http.post(url, payload);
  }

  sendGenerateAudioOrder(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/generate-audios`;
    return this.http.post(url, payload);
  }

  resetPack(id) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/packs/${id}/reset`;
    return this.http.post(url, {});
  }

  sendDelayEmail(data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/send-delay-email`;
    return this.http.post(url, data);
  }

  sendCustomEmail(data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/send-custom-email`;
    return this.http.post(url, data);
  }

  triggerDeliveryCourier(data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/delivery-couriers/trigger`;
    return this.http.get(url, data);
  }

  /**
   * GET Stocks Overview
   */
  stockOverview() {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/items/overview`;
    return this.http.get(url);
  }

  /**
   * GET Stocks Overview
   */
  // Items
  stockList(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/items`;
    return this.http.get(url, queryParams);
  }

  stockItem(id) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/items/${id}`;
    return this.http.get(url);
  }

  createStockItem(data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/items`;
    return this.http.post(url, data);
  }

  updateStockItem(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/items/${id}`;
    return this.http.put(url, data);
  }

  // Resources
  stockItemResourceList(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/items/resources`;
    return this.http.get(url, queryParams);
  }

  stockItemResource(id) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/items/resources/${id}`;
    return this.http.get(url);
  }

  createStockItemResource(data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/items/resources`;
    return this.http.post(url, data);
  }

  updateStockItemResource(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/items/resources/${id}`;
    return this.http.put(url, data);
  }

  // Transactions
  stockTransactionList(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/transactions`;
    return this.http.get(url, queryParams);
  }

  stockTransaction(id) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/transactions/${id}`;
    return this.http.get(url);
  }

  createStockTransaction(data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/transactions`;
    return this.http.post(url, data);
  }

  updateStockTransaction(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/stocks/transactions/${id}`;
    return this.http.put(url, data);
  }
  /**
   * Pack Automation Config
   */
  // Items
  packAutomationRulesByCountry(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/pack-automation/country-rules`;
    return this.http.get(url, queryParams);
  }

  packAutomationConfigList(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/pack-automation`;
    return this.http.get(url, queryParams);
  }

  packAutomationConfig(id) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/pack-automation/${id}`;
    return this.http.get(url);
  }

  removePackAutomationConfig(id) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/pack-automation/${id}`;
    return this.http.delete(url);
  }

  createPackAutomationConfig(data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/pack-automation`;
    return this.http.post(url, data);
  }

  updatePackAutomationConfig(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/pack-automation/${id}`;
    return this.http.put(url, data);
  }

  /**
   * POST an address fix requesto
   * @param payload: Object<any>
   */
  fixAddresses(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/fix-addresses`;
    return this.http.post(url, payload);
  }

  /**
   * POST an pages fix requesto
   * @param payload: Object<any>
   */
  fixPages(payload) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/updateItems`;
    return this.http.post(url, payload);
  }

  sendSubscriptionCode(data = {}) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/orders/send-subscription`;
    return this.http.post(url, data);
  }

  generateCogsReport(queryParams) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/reports/generate/cogs`
    return this.http.get(url, queryParams);
  }

  generateOrderStatusReport(queryParams) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/reports/generate/order-statuses`
    return this.http.get(url, queryParams);
  }

  generateCSVReport(queryParams = {}, reportName = 'orders') {
    // return this.http.get(url, queryParams);
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/reports/csv/${reportName}`;
    return this.http.get(url, queryParams);
  }

  downloadReport(queryParams = {}, reportName = 'orders', isCsv = false) {
    // return this.http.get(url, queryParams);
    const qs = new URLSearchParams(queryParams);
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/reports/${isCsv ? 'csv' : 'generate' }/${reportName}?${qs}`;

    // const url = `${this.config.baseUrl}/${this.config.services.pdf}/v1/invoices/${id}/generate`;
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            const type = xhr.getResponseHeader('Content-Type');
            // const disposition = xhr.getResponseHeader('Content-Disposition');
            // const attr  = disposition.split('=');
            // const name = attr[1].replace(/"/g, '');
            resolve({
              res: xhr.response,
              type,
              // name
            });
          } else
            reject(JSON.parse(xhr.response));
        }
      };

      xhr.open('GET', url, true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('userToken')}`);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }


  /**
   * GET a list of orders
   * @param queryParams
   */
   partnerOrders(queryParams?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/partner-orders`;
    return this.http.get(url, queryParams);
  }

  /**
   * GET overview
   */
  partnerOrderOverview(query?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/partner-orders/overview`;
    return this.http.get(url, query);
  }

  /**
   * GET Printer config routes
   */

  printerConfigs(query?) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/printer-config/`;
    return this.http.get(url, query);
  }

  createPrinterConfig(data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/printer-config`;
    return this.http.post(url, data);
  }

  updatePrinterConfig(id, data) {
    const url = `${this.config.baseUrl}/${this.config.services.orders}/v1/printer-config/${id}`;
    return this.http.put(url, data);
  }

  getPackEvents(id) {
    return this.http.get(`${this.config.baseUrl}/${this.config.services.orders}/v1/packs/${id}/events`);
  }
}
