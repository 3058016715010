
import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { CMS_ELEMENT_TYPES, CMS_ELEMENT_ATTRS, CMS_ELEMENT_STYLES } from '@shared/global.types';
import { AbstractModal } from '../../abstract-modal.component';

@Component({
  selector: 'app-cms-extra-modal',
  templateUrl: './cms-extra.component.html',
  styleUrls: ['../../general-modal.component.scss']
})
export class CMSExtraModal extends AbstractModal {
  types = [
    { name: 'Texto', code: CMS_ELEMENT_TYPES.TEXT },
    { name: 'Link', code: CMS_ELEMENT_TYPES.LINK },
    { name: 'Imágen', code: CMS_ELEMENT_TYPES.IMAGE }
  ];
  attributes = CMS_ELEMENT_ATTRS.map(val => {
    return {
      name: this.translate.instant(`cms.${val}`),
      code: val,
    };
  });

  styles = CMS_ELEMENT_STYLES.map(val => {
    return {
      name: this.translate.instant(`cms.${val}`),
      code: val,
    };
  });
  currentData: any = {};
  imageType = CMS_ELEMENT_TYPES.IMAGE;
  linkType = CMS_ELEMENT_TYPES.LINK;
  textType = CMS_ELEMENT_TYPES.TEXT;
  desktopImage = { url: '' };
  mobileImage = { url: '' };
  desktopFile;
  mobileFile;

  constructor(
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  isType(entryType: string) {
    if (this.form) {
      return this.form.get('type').value === entryType;
    }
  }

  get getContentFormControls() {
    return this.form.get('attributes') as FormArray;
  }

  get getStylesFormControls() {
    return this.form.get('styles') as FormArray;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  bootstrapForm(data: any = {}) {
    const attributes = { ...data.attributes };
    if (attributes.url) {
      this.desktopImage.url = attributes.url.desktop;
      this.mobileImage.url = attributes.url.mobile;
      delete attributes.url;
    }
    const content = this.formBuilder.array(this.parseToArray(this.formBuilder, attributes));
    const styles = this.formBuilder.array(this.parseToArray(this.formBuilder, data.styles));
    this.form = this.formBuilder.group({
      type: [ { value: data.type || 1, disabled: this.properties.update }, Validators.required],
      sort: [ { value: data.sort || 1, disabled: false }, Validators.required],
      status: [ { value: data.status || 'inactive', disabled: false }, Validators.required],
      attributes: content, // Extra Content in JSON
      styles
    });
  }

  getName() {
    return 'Extra CMS';
  }

  loadDesktopPicture = async (event: any) => {
    this.desktopFile = event.target.files;
    this.desktopImage = await this.imageService.getMediaAsBase64(this.desktopFile[0]);
  }

  loadMobilePicture = async (event: any) => {
    this.mobileFile = event.target.files;
    this.mobileImage = await this.imageService.getMediaAsBase64(this.mobileFile[0]);
  }

  async onSubmitPayload(payload) {
    const time = new Date().getTime();

    payload.attributes = {
      ...this.parseArray(this.getContentFormControls.value)
    };
    payload.styles = this.parseArray(this.getStylesFormControls.value);

    const { language } = this.properties;

    switch (this.form.get('type').value) {
      case CMS_ELEMENT_TYPES.IMAGE:
        payload.desktop_url = this.desktopFile ? await this.imageService.uploadCMSImage(
          this.desktopFile, 'extra', `desktop_${time}`, language) : this.desktopImage.url;
        payload.mobile_url = this.mobileFile ? await this.imageService.uploadCMSImage(
          this.mobileFile, 'extra', `mobile_${time}`, language) : this.mobileImage.url;
        break;
    }
    return payload;
  }
}
