import { config } from './config';

const _env = {
  production: true,
  baseUrl: 'https://service.micuento.com',
  basePdfUrl: 'https://generator.micuento.com',
  basePdfUrlPrefix: 'api',
  s3Bucket: 'https://micuento-web.s3.amazonaws.com',
};

export const environment = { ..._env, ...config() };
