import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractComponent } from '@shared/components/abstract/abstract.component';
import { HelpersService } from '@shared/services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isEmpty } from 'lodash';

@Component({
  selector: 'edit-order-modal',
  templateUrl: './edit-order-modal.component.html',
  styleUrls: ['./edit-order-modal.component.scss']
})
export class EditOrderModal extends AbstractComponent {

  properties: any = {};
  countries: any[] = [];
  provinces: any[] = [];
  search: any = {
    showList: false,
    filteredCountries: [],
  };
  shippingMethods = [
    { name: 'Standard', code: 'standard' },
    { name: 'Express', code: 'express' },
    { name: 'Rush', code: 'rush' }
  ];
  shippingType = [
    { name: 'Domicilio', code: 'address' },
    { name: 'Pickup', code: 'pickup-point' },
  ];
  orderForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private helpers: HelpersService,
  ) {
    super();
  }

  ngOnInit() {
    const data = this.properties.data || {};
    this.bootstrapForm(data);
  }

  /**
   * Handle form submission
   * @param formLogin form data
   */
  onSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      this.helpers.displayFormErrors(this.orderForm);
      return;
    }
    const data = this.properties.data || {};

    value.extra_data = this.removeEmpty(value.extra_data);

    if (data.extra_data && Object.keys(data.extra_data).length) {
      value.extra_data = {
        ...data.extra_data,
        ...value.extra_data,
      };
    }
    // Processing recipient;
    value.recipient = this.removeEmpty(value.recipient);
    this.helpers.setformAsValid(this.orderForm);
    this.activeModal.close({ payload: value });

  }

  close() {
    this.activeModal.close(false);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  private bootstrapForm(data: any = {}) {
    const recipient =  this.formBuilder.group({
      first_name: [ data.recipient ? data.recipient.first_name : undefined ],
      last_name: [ data.recipient ? data.recipient.last_name : undefined ],
    });
    const extra_data =  this.formBuilder.group({
      personal_id: [ data.extra_data && data.extra_data.personal_id ? data.extra_data.personal_id : undefined ],
      neighborhood: [ data.extra_data && data.extra_data.neighborhood ? data.extra_data.neighborhood : undefined ],
      street: [ data.extra_data && data.extra_data.street ? data.extra_data.street : undefined ],
      number: [ data.extra_data && data.extra_data.number ? data.extra_data.number : undefined ],
      complement: [ data.extra_data && data.extra_data.complement ? data.extra_data.complement : undefined ],
    });

    this.orderForm = this.formBuilder.group({
      recipient,
      extra_data,
      first_name: [ data.first_name || '' ],
      last_name: [ data.last_name || '' ],
      email: [ data.email || '' ],
      phone_number: [ data.phone_number || '' ],
      country: [ data.country || '' ],
      city: [ data.city || '' ],
      address_line: [ data.address_line || '' ],
      address_line_2: [ data.address_line_2 || '' ],
      zip_code: [ data.zip_code || '' ],
      province: [ data.province || '' ],
      delivery_courier_tracking: [ data.delivery_courier_tracking ],
      shipping_method: [ data.shipping_method ],
      shipping_type: [ data.shipping_type ],
    });
  }

  isCanary(province: string) {
    return province.includes('Palmas') || province.includes('Tenerife');
  }

  setProvinces(provinces) {
    this.provinces = provinces;
  }

  private removeEmpty(payload: any) {
    const newPayload = { ...payload };
    for (const key in newPayload) {
      if (!newPayload[key]) {
        delete newPayload[key];
      }
    }
    if (isEmpty(newPayload)) return null;
    return newPayload;
  }

}
