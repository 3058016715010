import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@env/environment';
import { uniqBy } from 'lodash';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit {

  config = environment;

  @Input() books: Array<any> = [];
  @Input() isPartner = false;

  booksData: Array<any> = [];

  constructor() {
  }

  ngOnInit() {
    const data = uniqBy(this.books, (val) => JSON.stringify([val.book_slug || val.product_slug, val.variant]));
    this.booksData = data.map((val) => {
      if (!val.product_details) val.product_details = {};
      if (!val.cover) val.cover = {};
      val.picture = val.product_details.picture || val.picture || val.cover.thumbnail || val.product_details.model_picture;
      val.model_picture = val.model_picture || val.product_details.picture;
      val.title = val.title || val.product_details.model_title;
      val.product_title = val.product_title || val.product_details.title;
      const books = this.books.filter(
        item => (item.book_slug || item.product_slug) === (val.book_slug || val.product_slug) && item.variant === val.variant);

      val.quantity = books.length > 1 ? books.reduce((sum, x) => sum.quantity + x.quantity) : books && books[0] ? books[0].quantity || 1 : 1;
      return val;
    });
  }

}
